// @flow

/**
 * Module dependencies.
 */

import { Collapse } from 'react-collapse';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `ItemProps` type.
 */

type ItemProps = {|
  active: boolean,
  children: Node,
  setActive: () => void,
  title: string
|};

/**
 * Export `Accordion` styled component.
 */

export const Accordion = styled.ul`
  grid-area: content;
  padding-top: 3rem;

  ${media.min('ms')`
    padding-top: 6rem;
  `}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  margin-bottom: 2rem;

  .ReactCollapse--collapse {
    transition: height ${theme('transitions.default')}; /* stylelint-disable-line plugin/no-low-performance-animation-properties */
  }
`;

/**
 * `ItemTitle` styled component.
 */

const ItemTitle = styled(Type.H4)`
  color: ${ifProp('active', color('green500'), color('blue800'))};
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  position: relative;

  &::after,
  &::before {
    background-color: ${ifProp('active', color('green200'), color('blue200'))};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &::after {
    background-color: ${color('green500')};
    display: ${ifProp('active', 'block', 'none')};
  }
`;

/**
 * Export `AccordionItem` component.
 */

export function AccordionItem(props: ItemProps): Node {
  const { active, children, setActive, title } = props;

  return (
    <ListItem>
      <ItemTitle
        active={active}
        as={'div'}
        onClick={() => setActive()}
        role={'button'}
      >
        <RawHtml>
          {title}
        </RawHtml>
      </ItemTitle>

      <Collapse isOpened={active}>
        {children}
      </Collapse>
    </ListItem>
  );
}
