// @flow

/**
 * Module dependencies.
 */

import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: Node,
  className?: string
};

/**
 * `CardWrapper` styled component.
 */

const CardWrapper = styled.div`
  background-color: white;
  background-image: linear-gradient(to top right, rgba(233, 238, 253, 0.9739), rgba(104, 127, 208, 0.1154) 62%, rgba(1, 16, 61, 0.1) 100%);
  border-radius: 0.75rem;
  box-shadow: -1px 2px 6px 0 rgba(104, 127, 208, 0.12);
`;

/**
 * `CardContent` styled component.
 */

const CardContent = styled.div`
  background-color: white;
  background-image: linear-gradient(45deg, rgba(61, 75, 161, 0.05) 0%, rgba(104, 127, 208, 0.03) 100%);
  border: 2px solid transparent;
  border-radius: 0.75rem;
  box-shadow: -1px 2px 6px 0 rgba(104, 127, 208, 0.12);
`;

/**
 * `Card` component.
 */

function Card(props: Props): Node {
  const { children, ...rest } = props;

  return (
    <CardWrapper {...rest}>
      <CardContent>
        {children}
      </CardContent>
    </CardWrapper>
  );
}

/**
 * Export `Card` component.
 */

export default Card;
