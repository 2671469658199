// @flow

/**
 * Module dependencies.
 */

import React, { useCallback, useRef, useState } from 'react';

/**
 * Export `useSlider` hook.
 */

export function useSlider(length: number, interval: ?number) {
  const [current, setCurrent] = useState(0);
  const timer = useRef();
  const resetTimer = useCallback(() => {
    if (!interval) {
      return;
    }

    clearTimeout(timer.current);

    timer.current = setTimeout(() => setCurrent(current => {
      return (current + 1) % length;
    }), interval);
  }, [interval, length]);

  const setActive = useCallback((slide: number) => {
    if (interval) {
      resetTimer();
    }

    setCurrent(slide);
  }, [interval, resetTimer]);

  React.useEffect(() => {
    if (!interval) {
      return;
    }

    timer.current = setTimeout(() => setCurrent(current => {
      return (current + 1) % length;
    }), interval);

    return () => clearTimeout(timer.current);
  }, [current, interval, length]);

  return [current, setActive];
}
