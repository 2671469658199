// @flow

/**
 * Module dependencies.
 */

import { Accordion, AccordionItem } from './accordion';
import { Box } from 'components/core/layout';
import { type ItemsList } from 'types/items-list';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import { useSlider } from 'hooks/use-slider';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  items: Array<ItemsList>
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas: 'content';

  ${media.min('ms')`
    grid-gap: 2.5rem;
    grid-template-areas: 'illustrations content';
    grid-template-columns: 1fr 1fr;
  `}
`;

/**
 * `IllustrationCell` styled component.
 */

const IllustrationCell = styled.div`
  align-items: center;
  display: grid;
  grid-area: illustrations;
  left: -30%;
  position: relative;
  width: 140%;

  ${media.max('ms')`
    display: none;
  `}

  ${media.min('lg')`
    left: 0;
    width: 100%;
  `}
`;

/**
 * `Illustration` styled component.
 */

const Illustration = styled.img`
  grid-column: 1 / -1;
  grid-row: 1;
  opacity: ${ifProp('active', 1, 0)};
  transform: translateY(${ifProp('active', 0, 10)}%);
  transition: ${theme('transitions.default')};
  transition-property: opacity, transform;
  width: 100%;
`;

/**
 * `ItemText` styled component.
 */

const ItemText = styled(Type.Paragraph).attrs({ as: 'span' })`
  color: ${color('grey700')};
`;

/**
 * `SlidingListWithImage` component.
 */

function SlidingListWithImage({ className, items }: Props): Node {
  const [activeSlide, setActiveSlide] = useSlider(items.length, null);

  return (
    <Grid className={className}>
      <IllustrationCell>
        {items.map(({ id, image }, index) => (
          <Illustration
            active={activeSlide === index}
            key={id}
            src={image?.file?.url}
          />
        ))}
      </IllustrationCell>

      <Accordion>
        {items.map(({ description, id, image, title }, index) => (
          <AccordionItem
            active={activeSlide === index}
            key={id}
            setActive={() => setActiveSlide(index)}
            title={title}
          >
            <ItemText>
              <RawHtml>
                {description}
              </RawHtml>
            </ItemText>

            <Box displayMs={'none'}>
              <Illustration
                active={activeSlide === index}
                key={id}
                src={image?.file?.url}
              />
            </Box>
          </AccordionItem>
        ))}
      </Accordion>
    </Grid>
  );
}

/**
 * Export `SlidingListWithImage` component.
 */

export default SlidingListWithImage;
