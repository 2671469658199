// @flow

/**
 * Module dependencies.
 */

import type { FluidImage } from 'types/image';

/**
 * Export `getFluidImage` util.
 */

export function getFluidImage(item: Object, key?: string): ?FluidImage {
  return item?.[key ?? 'image']?.file?.childImageSharp?.fluid;
}
