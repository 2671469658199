// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { color, media } from 'react-components/styles';
import { switchProp } from 'styled-tools';
import { useSocialNetworks } from 'hooks/use-social-networks';
import Link from 'components/core/links/link';
import React, { type Node } from 'react';
import discordIcon from 'assets/svg/social/discord.svg';
import linkedinIcon from 'assets/svg/social/linkedin.svg';
import mediumIcon from 'assets/svg/social/medium.svg';
import styled, { css } from 'styled-components';
import twitterIcon from 'assets/svg/social/twitter.svg';

/**
 * Icons list.
 */

const iconsList = {
  discord: discordIcon,
  linkedin: linkedinIcon,
  medium: mediumIcon,
  twitter: twitterIcon
};

/**
 * `Props` type.
 */

type Props = {|
  colorTheme?: 'primary' | 'secondary'
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom-left-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  padding: 1.5rem 0.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${media.max('lg')`
    display: none;
  `}

  ${switchProp('colorTheme', {
    primary: css`
      background-color: ${color.transparentize('blue800', 0.8)};
    `,
    secondary: css`
      background-color: ${color('grey100')};
      opacity: 0.5;
    `
  })}
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  display: grid;
  grid-row-gap: 1rem;
`;

/**
 * `SocialNetworks` component.
 */

function SocialNetworks({ colorTheme = 'primary' }: Props): Node {
  const socialNetworksLinks = useSocialNetworks();

  return (
    <Wrapper colorTheme={colorTheme}>
      <MenuList>
        {socialNetworksLinks.map(({ iconKey, label, url }) => {
          const socialNetworkIcon = iconsList?.[iconKey];

          return (
            <li key={iconKey}>
              <Link
                aria-label={label}
                colorTheme={colorTheme === 'primary' ? 'primary' : 'blue'}
                href={url}
                rel={'noopener'}
                target={'_blank'}
              >
                <Icon
                  aria-hidden
                  icon={socialNetworkIcon}
                  size={'1.5rem'}
                />
              </Link>
            </li>
          );
        })}
      </MenuList>
    </Wrapper>
  );
}

/**
 * Export `SocialNetworks` component.
 */

export default SocialNetworks;
